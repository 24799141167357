import React,{useState} from "react";
import {Button, Modal} from 'react-bootstrap'
import { View, Text, Image } from "react-native";
export default function CustomModal({showModal, setShowModal, modalData}) {

    const {title, description, btn1Text, btn2Text, btn1Click, btn2Click, descriptionKeys, showIcon} = modalData;
    // const [show, setShow] = useState(false);
  
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
  
    return (
      <>
  
        <Modal show={showModal} onHide={()=>setShowModal(false)} aria-labelledby="contained-modal-title-vcenter"
      centered backdrop='static'>
          <Modal.Header closeButton={false} style={{justifyContent:'flex-start'}}>
            {showIcon && <Image source={require('../assets/success.png')} style={{width:36, height:36}} />}
            <Modal.Title style={{lineHeight:1.2}}>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {description}
            {descriptionKeys && Object.keys(descriptionKeys).length>0 &&
              <View>
                <Text>{descriptionKeys.Paid_Amount}</Text>
              <Text>{descriptionKeys.Transaction_ID}</Text>
              </View>
            }
          </Modal.Body>
          <Modal.Footer>
            {btn1Text && <Button variant="secondary" onClick={btn1Click}>
              {btn1Text}
            </Button>}
            {btn2Text &&<Button variant="primary" onClick={btn2Click}>
              {btn2Text}
            </Button>}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  