import React from "react";
import { StyleSheet, TouchableOpacity, View, Image, Text } from "react-native";

import arrowUp from "../assets/arrow-up.png";
import arrowDown from "../assets/arrow-down.png";

export const Colors = {
  WHITE: "#ffffff",
  LIGHTGREEN: "#BABABA",
  GREEN: "green",
  GRAY: "#f7f7f7",
  LIGHTGRAY: "#C7C7C7",
  DARKGRAY: "#5E5E5E",
  CGRAY: "#ececec",
  OFFLINE_GRAY: "#535353",
};

const btnText = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "13px",
  lineHeight: "15px",
  letterSpacing: "-0.008em",
  color: "#6266C1",
  paddingLeft: "40px",
  // paddingRight: "10px",
  // display: "block",
};

export default function Accordian(props) {
  return (
    <View style={{ flex: 1 }}>
      <TouchableOpacity
        style={{
          flex: 1,
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
          paddingHorizontal: 10,
        }}
        onPress={() => {
          props.openId === props.myId
            ? props.toggle(-1)
            : props.toggle(props.myId);
        }}
      >
        <View style={{ flex: 6.5, flexDirection: "row", alignItems: "center" }}>
          <Text style={btnText}>
            {props.openId === props.myId ? "Hide Details" : "Show Details"}
          </Text>
          <Image
            source={props.openId === props.myId ? arrowUp : arrowDown}
            style={{
              width: 12,
              height: 12,
              marginLeft: 22,
              resizeMode: "contain",
            }}
          />
        </View>
      </TouchableOpacity>
      <View style={styles.parentHr} />
      {props.openId === props.myId && (
        <View style={{ flex: 1 }}>{props.children}</View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: Colors.DARKGRAY,
    paddingRight: 5,
  },
  parentHr: {
    height: 1,
    color: Colors.WHITE,
    width: "100%",
  },
});
