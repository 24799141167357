import React, { useEffect } from "react";

function PayuForm({
  submitRef,
  amount,
  firstname,
  email="amrit@shoocal.com",
  phone,
  txnid,
  hash,
  note,
}) {
  let url = "https://api.shoocal.com/pro/drab/payu_payment_check.php";
  // let key = "1XMcGS"; // test key
  let key = "lBsbuI"; // prod key
  // let actionUrl = "https://test.payu.in/_payment";
  let actionUrl = "https://secure.payu.in/_payment";

  return (
    <form action={actionUrl} id="form" method="post">
      <input type="hidden" name="firstname" value={firstname} />

      <input type="hidden" name="txnid" value={txnid} />

      <input type="hidden" name="productinfo" value={note} />

      <input type="hidden" name="email" value={email} />

      <input type="hidden" name="phone" value={phone} />

      <input type="hidden" name="key" value={key} />

      <input type="hidden" name="hash" value={hash} />

      <input type="hidden" name="amount" value={amount} />

      <input type="hidden" name="surl" value={url} />

      <input type="hidden" name="furl" value={url} />

      <input
        style={{ display: "none" }}
        ref={submitRef}
        type="submit"
        value="submit"
      />
    </form>
  );
}

export default PayuForm;
