import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Dimensions, ImageBackground, StyleSheet, Text } from "react-native";
import { useHistory,useParams, Link } from "react-router-dom";
import mobileLogo from "../assets/mobile-bg-logo.png";
import windowLogo from "../assets/bg-logo.png";
import Input from "../components/TextInput";
import { useLocation } from "react-router-dom";

const { width, height } = Dimensions.get("window");
function Landing() {
  let history = useHistory();
  let params = useParams();
  let location = useLocation()
  const [store_id, setStoreId] = useState(1);
  const [inputValue, setValue] = useState("");
  const [err, setError] = useState(false);

  useEffect(() => {
   
    if (location && location.pathname) {
        let getStoreId = location.pathname.split('/')
        setStoreId([getStoreId[2]]);
    } else {
    }
  }, [params]);

  const textStyle = {
    marginTop: "245px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "26px",
    lineHeight: "30px",
    letterSpacing: "-0.008em",
    color: "#FFFFFF",
  };

  const btnStyle = {
    height: 35,
  };

  const btnText = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "-0.03em",
    color: "#000",
    paddingLeft: "10px",
    paddingRight: "10px",
    display: "block",
  };

  const btnClick = () => {
    if(inputValue.length == 0){
      setError(true)
    } else if(inputValue.length>5){
      if(inputValue.includes('/')){
        let finalInput = inputValue.replaceAll('/','-');
        history.push(`/paybill/${finalInput}/${store_id?store_id:null}`);
      } else {
        history.push(`/paybill/${inputValue}/${store_id?store_id:null}`);
      }
      
    }
    
  };
  return (
    <div className="home-section" id="home" style={{}}>
      <ImageBackground
        source={width > 500 ? windowLogo : mobileLogo}
        style={{ width, height }}
      >
        <Container style={{ flex: 1 }}>
          <Row className="text-center">
            <Text style={textStyle}>Pay Your Bill Online</Text>
          </Row>

          <Row style={{ marginTop: 55, paddingLeft: 10, paddingRight: 10 }}>
            <Col>
              <Input
                placeholder={"Enter Mobile No / MRN"}
                inputValue={inputValue}
                onChangeText={(e) => setValue(e.target.value)}
              />
              {err && <Text style={{color:'white'}}>*Please enter valid mobile no / MRN</Text>}
            </Col>
            <Col style={{ paddingLeft: 0 }} className={"col-3"}>
              <Button variant="light" onClick={btnClick} style={btnStyle}>
                <Text style={btnText}>Search</Text>
              </Button>
            </Col>
          </Row>

          <div
            style={{
              position: "absolute",
              bottom: 40,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginLeft: -15,
            }}
          >
            <a
              onClick={()=>{
                window.open('https://www.dragarwal.com/','_blank')
              }}
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              <Text
                style={{
                  ...btnText,
                  color: "white",
                  textAlign: "right",
                  fontWeight: "400",
                }}
              >
                Our Website
              </Text>
            </a>

            <a
              onClick={()=>{
                window.open('https://eye-hospitals.dragarwal.com/','_blank')
              }}
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              <Text
                 style={{
                  ...btnText,
                  color: "white",
                  paddingLeft: 50,
                  fontWeight: "400",
                }}
              >
                Location
              </Text>
            </a>
            
            
          </div>
        </Container>
      </ImageBackground>
    </div>
  );
}

const styles = StyleSheet.create({
  textinput: {
    height: 26,
    borderWidth: 0.5,
    borderColor: "#0f0f0f",
    padding: 4,
    marginVertical: "1rem",
  },
  multiline: {
    borderWidth: 0.5,
    borderColor: "#0f0f0f",
    padding: 4,
    marginVertical: "1rem",
  },
});
export default Landing;
