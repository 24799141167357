import React, { useEffect } from "react";
import { View, StyleSheet, Text } from "react-native";
import { Router, Switch, Route } from "./router";
import { useHistory, Link, useParams, Redirect } from "react-router-dom";
import Home from "./screens/Home";
import Landing from "./screens/Landing";
import Payments from "./screens/Payments";
import Countdown from "./screens/Countdown";
import Payu from "./screens/PayU";

function App() {
  const btnText = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "-0.03em",
    color: "#fff",
    paddingLeft: "10px",
    paddingRight: "10px",
    display: "block",
  };
  return (
    <View style={styles.container}>
      <Router>
        <Switch>
          <Route path="/" exact component={Landing} />
          <Route path="/pay" component={Landing} />
          <Route path="/pay/:store_id" component={Landing} />
          <Route path="/paybill/:mrn/:store_id" component={Home} />
          <Route path="/paybill/:mrn" component={Home} />
          <Route path="/payments" component={Payments} />
          <Route path="/countdown" component={Countdown} />
          <Route path="/payuverification" component={Payu} />
          <Route
            path="*"
            component={() => {
              return (
                <main style={{ padding: "1rem" }}>
                  <p>There's nothing here!</p>
                  <div
                    style={{
                      height: 35,
                      background: "rgba(0,84,165,1)",
                      outline: 0,
                      textAlign: "center",
                      border: 0,
                    }}
                  >
                    <Link
                      style={{
                        display: "block",
                        margin: "1rem 0",
                        paddingTop: 9,
                      }}
                      to={"/pay"}
                      key={"pay"}
                      replace
                    >
                      <Text style={btnText}>Home</Text>
                    </Link>
                  </div>
                </main>
              );
            }}
          />
        </Switch>
      </Router>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});
export default App;
