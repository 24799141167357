/* eslint-disable */
import React from "react";
import { View, TouchableOpacity, Text } from "react-native";
export default class Timer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      start: Date.now(),
      diff: 0,
      minutes: "00",
      seconds: "00",
      duration: this.props.duration,
    };
    this.timerState = "";
    this.timerCount = "";
    this.count = 0;
  }

  componentDidMount() {
    clearInterval(this.timerCount);
    this.timerCount = setInterval(() => {
      this.timer();
    }, 1000);
  }

  starttimer() {
    clearInterval(this.timerCount);
    this.timerCount = setInterval(() => {
      this.timer();
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerCount);
  }

  timer() {
    let diff = this.state.diff;
    let minutes = this.state.minutes;
    let seconds = this.state.seconds;

    diff = this.state.duration - (((Date.now() - this.state.start) / 1000) | 0);
    minutes = (diff / 60) | 0;
    seconds = diff % 60 | 0;
    // minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    this.setState({ minutes: minutes, seconds: seconds });

    if (diff <= 0 || this.props.timerState) {
      this.props.setShowTimer(false);
      this.setState({ minutes: '00', seconds: '00' });
      clearInterval(this.timerCount);
    }
  }

  render() {
    return (
      <View style={this.props.rowStyle}>
        <View style={{ justifyContent: "flex-end", paddingBottom: 2 }}>
          <Text style={{color:'white', fontSize:28, fontWeight:'bold'}}>
            {this.state.minutes}m {this.state.seconds}s
          </Text>
        </View>
      </View>
    );
  }
}
