export default {
  "PatientInfo": [
      {
          "patientName": "SUNDAR",
          "patientMobile": "6666666600",
          "advanceAmount": 0,
          "patientMRN": "VLC/123790/19",
          "TotalAmounttobePaid": 350.0,
          "store_id": 1,
          "DueBills": [
              {
                  "AmounttobePaid": 350.0,
                  "Department": "",
                  "billType": "OP",
                  "billId": 2429030,
                  "description": [
                      {
                          "itemName": "Consultation Charges",
                          "quantity": "1"
                      }
                  ],
                  "billDate": "2022-04-04 12:15:18",
                  "billNo": "VLC/OP/55926"
              }
          ]
      },
      {
          "patientName": "PRAVIN",
          "patientMobile": "6666666600",
          "advanceAmount": 0,
          "patientMRN": "VLC/123791/19",
          "TotalAmounttobePaid": 100437.0,
          "DueBills": [
              {
                  "AmounttobePaid": 437.0,
                  "Department": "",
                  "billType": "OP",
                  "billId": 2429032,
                  "description": [
                      {
                          "itemName": "Blood Grouping and Rh Factor",
                          "quantity": "1"
                      },
                      {
                          "itemName": "Review consultation-Free",
                          "quantity": "1"
                      }
                  ],
                  "billDate": "2022-04-04 12:15:18",
                  "billNo": "VLC/OP/55928"
              },
              {
                  "AmounttobePaid": 100000.0,
                  "Department": "",
                  "billType": "OP",
                  "billId": 2429031,
                  "description": [
                      {
                          "itemName": "Fast Track Consultation Charges",
                          "quantity": "1"
                      }
                  ],
                  "billDate": "2022-04-04 12:15:18",
                  "billNo": "VLC/OP/55927"
              }
          ]
      }
  ]
}