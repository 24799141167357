import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Dimensions, View, Image, Buttonm, TextInput } from "react-native";
import search from "../assets/search.png";
export default function searchBar({
  placeholder,
  inputValue,
  onChangeText,
  searchImage = true,
  style,
}) {
  const textStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    letterSpacing: "-0.008em",
    color: "rgba(0,84,165,1)",
    marginLeft: 10,
    border: 0,
    outline: 0,
    width: "100%",
    boxShadow:'0 0',
    touchAction:'none'
  };
  return (
    <View
      style={[
        {
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: "white",
          height: 35,
          padding: 10,
          borderRadius: 4,
        },
        style,
      ]}
    >
      {searchImage && (
        <Image
          //   style={faqStyles.imT}
          style={{ width: 16, height: 16 }}
          source={search}
        />
      )}
      <Form.Control
    id="inputPassword5"
    aria-describedby="passwordHelpBlock"
    value={inputValue}
    placeholder={placeholder}
    onChange={onChangeText}
    style={textStyle}
    autoComplete="off"
    
  />
      {/* <TextInput
        placeholder={placeholder}
        onChangeText={onChangeText}
        style={textStyle}
        placeholderTextColor={"rgba(0,84,165,1)"}
        value={inputValue}
        onFocus={(e)=> e.preventDefault()}
        
      /> */}
    </View>
  );
}
