import React, { useState, useEffect, useCallback, useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import {
  Dimensions,
  ImageBackground,
  StyleSheet,
  Text,
  Image,
  FlatList,
  View,
  TouchableOpacity,
} from "react-native";
import { useHistory, Link, useParams } from "react-router-dom";
import Modal from "../components/Modal";
import logo from "../assets/logo.png";
import windowLogo from "../assets/gradientbg.png";
import Input from "../components/TextInput";
import mockData from "./mockData";
import moment from "moment";
import Accordian from "../components/Accordian";
import PayuForm from "../components/PayuForm";
import axios from "axios";
const { width, height } = Dimensions.get("window");

function Home() {
  let history = useHistory();
  let params = useParams();

  const submitRef = useRef()
  const [refreshing, setRefresh] = useState(false);
  const [error, setError] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [catagoryID, changecatagoryID] = useState(-1);
  const [ip, setIP] = useState("");
  const [selected, setSelected] = useState([]);
  const [careData, setCareData] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [queruMrn, setQueryMrn] = useState("");
  const [store_id, setStoreId] = useState("");
  const [err, setMRNError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});

  const [hash, setHash] = useState(null)
  const [txnid, setTxnId] = useState(null)

  const getIPData = async () => {
    // const res = await axios.get("https://geolocation-db.com/json/");
    // setIP(res.data.IPv4);
  };
  useEffect(() => {
    if (params && params.mrn) {
      if (params.mrn.includes("-")) {
        let finalInput = params.mrn.replaceAll("-", "/");
        setQueryMrn(finalInput);
      } else {
        setQueryMrn(params.mrn);
      }

      if (params.store_id) {
        setStoreId(params.store_id);
      }
      getData(params.mrn);
    } else {
      if (params.store_id) {
        window.location.replace(`/pay/${params.store_id}`);
      } else {
        window.location.replace("/pay");
      }
      // history.replace(`/pay`);
      
    }
  }, []);

  const getData = async (mrnNO) => {
    //   const username = "vlcagarwal";
    //   const password = "@g@rw@l$1";
    //   var basicAuth = "Basic " + btoa(username + ":" + password);

    let finalMRN = "";
    if (mrnNO.includes("/")) {
      finalMRN = mrnNO.replaceAll("/", "-");
    } else {
      finalMRN = mrnNO;
    }
    setRefresh(true);
    setSelected([]);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic bW9iaWxlY2FzaGllcjpJJGVhbWUkNQ==");
    myHeaders.append(
      "Cookie",
      "AWSALB=l7C778+yOE4tKy4Z18iqJyXohZTpTh6EbNze9iuwS8ivI5iSxXcFR1JR+qzp3gumdskej6D73x4mnWo0d+lWiEsUkIaABAOJCHqT2f5/VOHfclxJHBcdWz1tGTuP; AWSALBCORS=l7C778+yOE4tKy4Z18iqJyXohZTpTh6EbNze9iuwS8ivI5iSxXcFR1JR+qzp3gumdskej6D73x4mnWo0d+lWiEsUkIaABAOJCHqT2f5/VOHfclxJHBcdWz1tGTuP; JSESSIONID=E8BB844F4568F2AD1D0C94284037049E"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      fetch(
        `https://app.carehis.com/services/api/form/DUEBILL/${finalMRN}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          setData(result);
          getIPData();
          setError(false);
          setRefresh(false);
        })
        .catch((error) => {
          console.log("error", error);
          // setData(mockData);
          setError(true);
          setRefresh(false);
        });
    } catch (error) {
      console.log("error", error);
      setError(true);
      setRefresh(false);
      // setData(mockData);
    }
  };

  const setData = (userData) => {
    if (userData && userData.PatientInfo.length == 0) {
      setCareData([]);
      setTotalAmount(0);
    }
    if (userData && userData.PatientInfo.length > 0) {
      setUserDetails(userData.PatientInfo[0]);
      if (userData.PatientInfo.length >= 1) {
        let tempBills = [];
        if (params.store_id) {
          userData.PatientInfo.map((item, index) => {
            let filterBills = item.DueBills.filter(
              (bill) => bill.BranchCode == params.store_id.toUpperCase()
            );
            tempBills.push(...filterBills);
          });
        } else {
          userData.PatientInfo.map((item, index) => {
            tempBills.push(...item.DueBills);
          });
        }
        // setCareData(tempBills);
        let array = [];
        let total = 0;
        for (let i = 0; i < tempBills.length; i++) {
          array.push(tempBills[i]);
          total += tempBills[i].AmounttobePaid;
        }
        let Sorteddata = array.sort((a, b) => {
          return new Date(b.billDate) - new Date(a.billDate);
        });
        setCareData(Sorteddata);
        setSelected([...Sorteddata]);
        setTotalAmount(total);
      } else {
        setCareData(userData.PatientInfo[0].DueBills);
      }
    } else {
      setUserDetails({});
    }
  };

  async function handleCheckbox(id, amount, selectedData) {
    let array = selected;
    // const index = await array.findIndex(selectedData.billId);
    const index = array.findIndex((x) => x.billId === selectedData.billId);
    if (index > -1) {
      await array.splice(index, 1);
      let amt = totalAmount - amount;
      setTotalAmount(amt);
    } else {
      array = [...array, selectedData];
      let amt = totalAmount + amount;
      setTotalAmount(amt);
    }

    await setSelected([...array]);
  }

  const btnStyle = {
    height: 35,
    background: "rgba(0,84,165,1)",
    outline: 0,
    border: 0,
  };

  const btnText = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "-0.03em",
    color: "#fff",
    paddingLeft: "10px",
    paddingRight: "10px",
    display: "block",
  };

  const proceedPay = () => {
    if (selected.length > 0) {
      hitPayU()
    } else {
      alert("please select items");
    }
  };

  const hitPayU = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic bW9iaWxlY2FzaGllcjpJJGVhbWUkNQ==");
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("total_amount", totalAmount);
    urlencoded.append("name", userDetails.patientName);
    urlencoded.append("phone", userDetails.patientMobile);
    urlencoded.append("mrn_number", userDetails.patientMRN);
    urlencoded.append("store_id", params.store_id?params.store_id:'');
    urlencoded.append("stringified_bill_details", JSON.stringify(selected));
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(
      "https://api.shoocal.com/pro/drab/userapp/userregisterPayU",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if(result.success){
          openThroughPayu(result.data)
        }
      })
      .catch((error) => console.log("error", error));
  };

  const openThroughPayu = (res) => {
        setTxnId(res.paymentID)
        setHash(res.hash)
        localStorage.setItem("paymentID", res.paymentID)
        const interval = setInterval(() => {
          if (submitRef && submitRef.current) {
            submitRef.current.click();
            clearInterval(interval);
          }
        }, 100);
      }
  const HeaderComponent = () => {
    return (
      <Row style={{ marginTop: 15, marginBottom: 15 }}>
        <Col>
          <Text style={{ ...btnText, color: "#000" }}>
            Bill Details {careData.length > 0 ? `(${careData.length})` : ""}
          </Text>
        </Col>
      </Row>
    );
  };

  const renderItem = ({ item, index }) => {
    return (
      <Row
        style={{
          alignItems: "center",
          backgroundColor: selected.find((select) => item.billId === select)
            ? "#98F4DE"
            : index % 2
            ? "white"
            : "#EFFFFF",
          padding: 16,
        }}
      >
        <Col>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <TouchableOpacity
              onPress={() =>
                handleCheckbox(item.billId, item.AmounttobePaid, item)
              }
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              {selected.find((select) => item.billId === select.billId) ? (
                <Image
                  source={require("../assets/checkbox-checked.png")}
                  style={{ width: 25, height: 25 }}
                />
              ) : (
                <Image
                  source={require("../assets/checkbox.png")}
                  style={{ width: 25, height: 25 }}
                />
              )}
            </TouchableOpacity>
            <div style={{ width: "65%", paddingLeft: 15 }}>
              <Text style={{ display: "block", ...btnText, color: "#000" }}>
                {item.billNo}
              </Text>
              <Text
                style={{
                  display: "block",
                  ...btnText,
                  color: "#434343",
                  fontWeight: "400",
                  fontSize: 13,
                }}
              >
                OP/ID: #{item.billId}
              </Text>
              <Text
                style={{
                  display: "block",
                  ...btnText,
                  color: "#757575",
                  fontWeight: "400",
                  fontSize: 12,
                }}
              >
                {moment(item.billDate).format("DD-MM-YYYY")}
              </Text>
            </div>
            <Text
              style={{
                display: "block",
                ...btnText,
                color: "#000",
                width: "30%",
                textAlign: "right",
              }}
            >
              ₹ {item.AmounttobePaid}
            </Text>
          </View>
          <View style={{ marginTop: 16 }}>
            <Accordian
              title={"About Me"}
              myId={index}
              openId={catagoryID}
              toggle={changecatagoryID}
            >
              <View
                style={{
                  marginTop: 20,
                  borderRadius: 10,
                  borderWidth: 1,
                  borderColor: "#E0E0E0",
                }}
              >
                <View
                  style={{
                    paddingVertical: 20,
                    paddingHorizontal: 10,
                    flexDirection: "row",
                    borderBottomWidth: 1,
                    borderBottomColor: "#E0E0E0",
                  }}
                >
                  <Text
                    style={{
                      display: "block",
                      ...btnText,
                      color: "#000",
                      paddingHorizontal: 0,
                      width: "70%",
                    }}
                  >
                    Item Name
                  </Text>
                  <Text
                    style={{
                      display: "block",
                      ...btnText,
                      color: "#000",
                      paddingHorizontal: 0,
                      width: "30%",
                    }}
                  >
                    Quantity
                  </Text>
                  {/* <Text
                    style={{
                      display: "block",
                      ...btnText,
                      color: "#000",
                      paddingHorizontal: 0,
                      width: "30%",
                      textAlign: "center",
                    }}
                  >
                    Price
                  </Text> */}
                </View>
                {item.description.map((entity, index) => {
                  return (
                    <View
                      key={entity.itemName}
                      style={{
                        paddingVertical: 20,
                        paddingHorizontal: 10,
                        flexDirection: "row",
                      }}
                    >
                      <Text
                        style={{
                          display: "block",
                          ...btnText,
                          color: "#000",
                          paddingHorizontal: 0,
                          width: "70%",
                        }}
                      >
                        {entity.itemName}
                      </Text>
                      <Text
                        style={{
                          display: "block",
                          ...btnText,
                          color: "#000",
                          paddingHorizontal: 0,
                          width: "30%",
                          paddingLeft: 25,
                        }}
                      >
                        {" "}
                        {entity.quantity}
                      </Text>
                      {/* <Text
                        style={{
                          display: "block",
                          ...btnText,
                          color: "#000",
                          paddingHorizontal: 0,
                          width: "30%",
                          textAlign: "center",
                        }}
                      >
                        ₹ {entity.itemPrice ? entity.itemPrice : 0}
                      </Text> */}
                    </View>
                  );
                })}
              </View>
            </Accordian>
          </View>
        </Col>
      </Row>
    );
  };
  return (
    <div
      className="home-section"
      id="home"
      style={{
        backgroundColor:
          "linear-gradient(27.51deg, #6C069C -30.15%, #0B67C9 39.35%, #187DF3 108.1%)",
      }}
    >
      <ImageBackground source={windowLogo} style={{ width, height: height }}>
        <Container style={{ flex: 1 }}>
          <Row style={{ paddingTop: 30, paddingLeft: 10 }}>
            <Col>
              <Image source={logo} style={{ width: 139, height: 32 }} />
            </Col>
          </Row>

          <Row style={{ marginTop: 40, paddingLeft: 10, paddingRight: 10 }}>
            <Col>
              <Input
                placeholder={"Search"}
                inputValue={queruMrn}
                onChangeText={(e) => setQueryMrn(e.target.value)}
              />
              {err && (
                <Text style={{ color: "white" }}>
                  *Please enter valid mobile no / MRN
                </Text>
              )}
            </Col>
            <Col style={{ paddingLeft: 0 }} className={"col-3"}>
              <Button
                variant="light"
                onClick={() => {
                  if (queruMrn.length == 0) {
                    setMRNError(true);
                  } else {
                    setMRNError(false);
                    getData(queruMrn);
                  }
                }}
                style={btnStyle}
              >
                <Text style={btnText}>Search</Text>
              </Button>
            </Col>
          </Row>

          <Row style={{ marginTop: 23, marginBottom: 23 }}>
            <Col>
              <Text style={{ ...btnText }}>
                {userDetails.patientName
                  ? "Hello " + userDetails.patientName + ","
                  : ""}
              </Text>
            </Col>
          </Row>

          <Row
            style={{
              background: "white",
              height: error || careData.length == 0 ? 200 : height * 0.45,
              marginLeft: 10,
              marginRight: 10,
              borderRadius: 20,
            }}
          >
            {HeaderComponent()}
            <View
              style={{
                overflow: "scroll",
                height: error || careData.length == 0 ? 200 : height * 0.4,
                paddingLeft: 0,
                paddingRight: 0,
                borderRadius: 20,
              }}
            >
              <FlatList
                keyExtractor={(careData) => careData.billId}
                data={careData}
                renderItem={renderItem}
                ListEmptyComponent={() => {
                  return (
                    <View style={{ alignItems: "center", marginTop: 20 }}>
                      <Text
                        style={{
                          ...btnText,
                          color: "black",
                          fontWeight: "600",
                        }}
                      >
                        {refreshing
                          ? "Loading..."
                          : error
                          ? "We are facing some technical challanges, please try after some time"
                          : "Details Not Found!"}
                      </Text>
                    </View>
                  );
                }}
              />
            </View>
          </Row>

          {(!error || careData.length != 0) && (
            <Row
              style={{
                marginTop: 36,
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                bottom: 15,
                width: "100%",
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <Col>
                <Text style={{ ...btnText, fontSize: 16 }}>
                  Total
                  <Text
                    style={{
                      ...btnText,
                      fontSize: 16,
                      display: "inline",
                    }}
                  >
                    ₹ {totalAmount}
                  </Text>
                </Text>
              </Col>
              <Col>

                    {hash && <PayuForm 
                    submitRef={submitRef}
                    note={`Payment - ${params.store_id}`}
                    amount={totalAmount}
                    firstname={userDetails.patientName}
                    phone={userDetails.patientMobile}
                    txnid={txnid}
                    hash={hash}
                    />}
                    <Button
                  variant="light"
                  type="submit"
                  onClick={proceedPay}
                  style={{
                    background:
                      selected.length > 0
                        ? "rgba(255,255,255,1)"
                        : "rgba(255,255,255,.4)",
                    height: 44,
                  }}
                >
                  <Text
                    style={{ ...btnText, color: "black", fontWeight: "600" }}
                  >
                    Proceed to Pay
                  </Text>
                </Button>
                
              </Col>
            </Row>
          )}
          <Modal
            setShowModal={setShowModal}
            showModal={showModal}
            modalData={modalData}
          />
        </Container>
      </ImageBackground>
    </div>
  );
}

const styles = StyleSheet.create({
  textinput: {
    height: 26,
    borderWidth: 0.5,
    borderColor: "#0f0f0f",
    padding: 4,
    marginVertical: "1rem",
  },
  multiline: {
    borderWidth: 0.5,
    borderColor: "#0f0f0f",
    padding: 4,
    marginVertical: "1rem",
  },
});
export default Home;
