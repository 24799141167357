import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import {
  Dimensions,
  ImageBackground,
  StyleSheet,
  Text,
  Image,
  FlatList,
  View,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { useHistory, Link, useLocation } from "react-router-dom";
import logo from "../assets/logo.png";
import windowLogo from "../assets/gradientbg.png";
import Timer from "../components/OTPTimer";
// import mockData from "./mockData";
import axios from "axios";
import Accordian from "../components/Accordian";
import Modal from "../components/Modal";
const { width, height } = Dimensions.get("window");

function Countdown() {
  let history = useHistory();
  let params = useLocation();
  const [userDetails, setUserDetails] = useState({});
  const [amount, setAmount] = useState("");
  const [bharatpeTxnId, setTxnId] = useState("");
  const [paymentID, setPaymentId] = useState("");
  const [showTimer, setShowTimer] = useState(true);
  const [timer, setTimer] = useState(600)
  const [showModal, setShowModal] = useState(false);
  const [paymentSuccess, setShowSuccess]  = useState(false);
  const [modalData, setModalData] = useState({});
  const [fromApp, setFromApp] = useState('');
  useEffect(() => {
    if (params && params.state) {
      setTxnId(params.state.bharatpeTxnId);
      setPaymentId(params.state.paymentID);
      setAmount(params.state.amount)
      setFromApp(params.state.from)
      // timer()
    } else {
      window.location.replace("/pay");
    }
  }, [params]);


  useEffect(() => {
    if (bharatpeTxnId && paymentID) {
      const id = setInterval(validatePayment, 5000);
      if (!showTimer && !paymentSuccess) {
        clearInterval(id);
        conditionalPopup("TIMEOUT");
        //
      } else if(!showTimer) {
        clearInterval(id);
      }
      return () => clearInterval(id);
    }
  }, [bharatpeTxnId, paymentID, showTimer]);

  const validatePayment = () => {
    
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic bW9iaWxldXNlcjpJJGVhbWUkNQ=");
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("paymentID", paymentID);
    urlencoded.append("bharatpeTxnId", bharatpeTxnId);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(
      "https://api.shoocal.com/pro/drab/userapp/verifyPaymentUPI",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        paymentRequestData(result);
        if (result.success) {
          let tempParsed = JSON.parse(result.data);
          if(tempParsed.data.paymentStatus === 'SUCCESS'){
            hitOnSuccess(result.extra_data)
          }
        }
        
        
      })
      .catch((error) => console.log("error", error));
  };

  const paymentRequestData = (data) => {
    if (data.success) {
      const parsedData = JSON.parse(data.data);
      conditionalPopup(parsedData.data.paymentStatus);
      // if(parsedData.data.paymentStatus === 'SUCCESS'){
      //   hitOnSuccess(data.extra_data)
      // }
    }
  };

  const conditionalPopup = (status) => {
    switch (status) {
      case "TIMEOUT":
        showTimeoutPopUp();
        break;

      case "SUCCESS":
        showSuccessPopUp();
        break;

      case "FAILED":
        showFailedPopUp();
        break;

      case "CANCEL_TRANSACTION":
        showCancelPopUp();
        break;

      default:
        break;
    }
  };

  const showTimeoutPopUp = () => {
    setModalData({
      title: "Transaction Timeout",
      description: "Transaction timed out. How would you like to proceed?",
      // btn1Text: "Retry",
      btn2Text: "Retry",
      // btn1Click: () => {
      //   setShowModal(false);
      //   history.goBack();
      // },
      btn2Click: () => {
        history.goBack();
        setShowModal(false);
      },
    });
    setShowModal(true);
  };

  const showSuccessPopUp = () => {
    setShowTimer(false);
    setShowSuccess(true);
    setTimer(0)
    setModalData({
      showIcon : true,
      title: "Payment Successful",
      description: `Thanks, We have received your payment.`,
      descriptionKeys : {
        'Paid_Amount' : 'Paid Amount - ₹' + amount,
        'Transaction_ID' : 'Transaction ID - ' +params.state.bharatpeTxnId
      },
      btn2Text: "OK",
      btn2Click: () => {
        history.push(`/pay/${params.state.store_id}`);
        localStorage.removeItem('payerVpa');
        setShowModal(false);
      },
    });
    setShowModal(true);
    
  };

  const showFailedPopUp = () => {
    setShowTimer(false);
    setShowSuccess(true)
    setTimer(0)
    setModalData({
      title: "Transaction Failed",
      description:
        "Any amount deducted will be refunded within 3-5 working days into your bank account. How would you like to proceed?",
      // btn1Text: "OK",
      btn2Text: "Retry",
      // btn1Click: () => {
      //   setShowModal(false);
      //   history.goBack();
      // },
      btn2Click: () => {
        // history.push(`/paybill/${params.state.mrn}/${params.state.store_id}`);
        history.goBack();
        // setShowTimer(false)
        setShowModal(false);
      },
    });
    setShowModal(true);
  };

  const showCancelPopUp = () => {
    setModalData({
      title: "Are you sure you want to cancel payment?",
      description:
        "On cancelling the payment, the payment request will get cancelled only if the payment is not yet completed",
      btn1Text: "Yes, Cancel",
      btn2Text: "Dismiss",
      btn1Click: () => {
        setShowModal(false);
        history.goBack();
      },
      btn2Click: () => {
        setShowModal(false);
      },
    });
    setShowModal(true);
    
  };

  const hitOnSuccess = (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic bW9iaWxldXNlcjpJJGVhbWUkNQ==");
    myHeaders.append(
      'Content-Type', 'application/json'
    );

    var urlencoded = {
      "PaymentDetails": data.PaymentDetails
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(urlencoded),
      redirect: "follow",
    };
    fetch(
      `https://app.carehis.com//services/api/form/BILLDETAIL`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
      });
  }
  return (
    <div
      className="home-section"
      id="home"
      style={{
        backgroundColor:
          "linear-gradient(27.51deg, #6C069C -30.15%, #0B67C9 39.35%, #187DF3 108.1%)",
      }}
    >
      <ImageBackground source={windowLogo} style={{ width, height: height }}>
        <Container style={{ width, height: height }}>
          <Row style={{ paddingTop: 30, paddingLeft: 30 }}>
            <Col>
              <Image source={logo} style={{ width: 139, height: 32 }} />
            </Col>
          </Row>

          <Row
            style={{
              // background: "white",
              marginLeft: 10,
              marginRight: 10,
              borderRadius: 5,
              marginTop: height*.05,
              padding: 15,
              paddingLeft: 10,
              paddingRight: 10,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!paymentSuccess &&<><ActivityIndicator
              animating={true}
              style={{ marginBottom: 30 }}
              size={40}
              color={"white"}
            />
           <Timer
              duration={timer}
              setShowTimer={setShowTimer}
              timerState={paymentSuccess}
              rowStyle={{
                justifyContent: "center",
                flexDirection: "row",
                marginBottom:30
              }}
            />
            <Text style={{ color: "white", fontSize: 20, textAlign:'center' }}>
              {
                fromApp == 'viaApp' ? 
                'Choose your preferred UPI App and enter your UPI PIN to complete your payment.'
                : 
                'Click on the notification received on your UPI App & enter your UPI PIN.'
              }
              
            </Text>
            </>}
           
            
          </Row>
          {!paymentSuccess &&<><Row
            style={{
              position: "absolute",
              bottom: 40,
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            <Text
              style={{
                textAlign: "center",
                marginTop: 15,
                color: "white",
                fontSize: 16,
              }}
            >
              NOTE: You will be redirected to another page once your payment is
              successful
            </Text>
            <Text
              style={{
                textAlign: "center",
                marginTop: 15,
                color: "white",
                fontSize: 16,
              }}
            >
              Do not close this page or press back.
            </Text>

            <TouchableOpacity
              onPress={() => conditionalPopup("CANCEL_TRANSACTION")}
            >
              <Text
                style={{
                  textAlign: "center",
                  margin: 'auto',
                  marginTop: 20,
                  color: "white",
                  fontSize: 20,
                  border:'1px solid white',
                  paddingHorizontal:10,
                  paddingVertical:4,
                  borderRadius: 4
                }}
              >
                Cancel Payment
              </Text>
            </TouchableOpacity>
          </Row></>}
          <Modal
            setShowModal={setShowModal}
            showModal={showModal}
            modalData={modalData}
          />
        </Container>
      </ImageBackground>
    </div>
  );
}

const styles = StyleSheet.create({
  textinput: {
    height: 26,
    borderWidth: 0.5,
    borderColor: "#0f0f0f",
    padding: 4,
    marginVertical: "1rem",
  },
  multiline: {
    borderWidth: 0.5,
    borderColor: "#0f0f0f",
    padding: 4,
    marginVertical: "1rem",
  },
});
export default Countdown;
