import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import {
  Dimensions,
  ImageBackground,
  StyleSheet,
  Text,
  Image,
  FlatList,
  View,
  TouchableOpacity,
  Platform
} from "react-native";
import { useHistory, Link, useLocation } from "react-router-dom";
import logo from "../assets/logo.png";
import windowLogo from "../assets/gradientbg.png";
import Input from "../components/TextInput";
// import mockData from "./mockData";
import axios from "axios";
import Accordian from "../components/Accordian";
const { width, height } = Dimensions.get("window");

function Payments() {
  let history = useHistory();
  let params = useLocation();
  const [userDetails, setUserDetails] = useState({});
  const [inputValue, setValue] = useState("");
  const [showInput, setShow] = useState(false);
  const [error, showError] = useState(false);

  useEffect(() => {
    // console.log(getMobileOperatingSystem())
    if (params && params.state) {
      setUserDetails(params.state);
      if(localStorage.getItem('payerVpa')){
        setValue(localStorage.getItem('payerVpa'))
      }
    } else {
      window.location.replace(`/pay/${userDetails.store_id}`);
    }
  }, [params]);

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }
    else if (userAgent.match(/Windows Phone/i))  { return 'WindowsPhone';  }
    if (/android/i.test(userAgent)) {
        return "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

  const createPayment = (from) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic bW9iaWxlY2FzaGllcjpJJGVhbWUkNQ==");
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    if(from && from == 'viaApp'){
      urlencoded.append("payerVpa", "");
    } else {
      if (inputValue) {
        urlencoded.append("payerVpa", inputValue);
        localStorage.setItem('payerVpa', inputValue)
      } else {
        urlencoded.append("payerVpa", "");
      }
    }
    
    urlencoded.append("total_amount", userDetails.amount);
    urlencoded.append("name", userDetails.patientName);
    urlencoded.append("phone", userDetails.patientMobile);
    urlencoded.append("mrn_number", userDetails.patientMRN);
    urlencoded.append("store_id", userDetails.store_id?userDetails.store_id:'');
    urlencoded.append("bill_details", userDetails.selectedBills);
    urlencoded.append("stringified_bill_details", JSON.stringify(userDetails.selectedBills));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(
      "https://api.shoocal.com/pro/drab/userapp/userregister",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        paymentRequestData(result, from);
      })
      .catch((error) => console.log("error", error));
  };

  const paymentRequestData = (data, from) => {
    if (data.success) {
      const parsedData = JSON.parse(data.data);
      if (parsedData.success) {
        if(parsedData.data.responseCode === '100' ||parsedData.data.responseCode === 100 ){
        if (parsedData.data.upiString) {
          // console.log('upiString',parsedData.data.upiString);
          if(getMobileOperatingSystem() === 'Android'){
            if(from && from == 'viaApp'){
              window.open(parsedData.data.upiString, "_blank");
            }
            
          } else{
            // window.open(parsedData.data.paymentLink, "_blank");
          }
          history.push({
            pathname: "/countdown",
            // search: '?query=abc',
            state: {
              paymentID: parsedData.data.orderId,
              bharatpeTxnId: parsedData.data.bharatpeTxnId,
              timer: true,
              mrn: userDetails.mrn,
              store_id: userDetails.store_id,
              amount: userDetails.amount,
              from : from && from == 'viaApp' ?'viaApp' : 'viaUPI'
            },
          });
        } else {
          history.push({
            pathname: "/countdown",
            state: {
              paymentID: parsedData.data.orderId,
              bharatpeTxnId: parsedData.data.bharatpeTxnId,
              timer: true,
              mrn: userDetails.mrn,
              store_id: userDetails.store_id,
              amount: userDetails.amount
            },
          });
        }
      }
    }
    }
  };

  const renderItem = ({ item, index }) => {
    return (
      <Row
        style={{
          alignItems: "center",
          padding: 5,
        }}
      >
        <Col>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Image
              source={item.logo}
              style={{ width: 30, height: 32, resizeMode: "contain" }}
            />
            <div style={{ width: "70%", paddingLeft: 5 }}>
              {/* <Link 
              to={{
                pathname: `/countdown`, 
                state:{timer: true, mrn: userDetails.mrn}
              }}
              style={{textDecoration:'none'}}
              >
              <Text style={{ ...btnText, color: "black", fontWeight:'400' }}>
              {item.name}
                </Text>
            </Link> */}

              <TouchableOpacity
                onPress={() => createPayment('viaApp')}
                style={{ justifyContent: "center" }}
              >
                <Text style={{ ...btnText, color: "black", fontWeight: "400" }}>
                  {item.name}
                </Text>
              </TouchableOpacity>
            </div>
          </View>
        </Col>
      </Row>
    );
  };

  const btnText = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "-0.03em",
    color: "#fff",
    paddingLeft: "10px",
    paddingRight: "10px",
    display: "block",
  };

  const btnStyle = {
    height: 35,
    background: "rgba(0,84,165,1)",
    outline: 0,
    border: 0,
  };

  return (
    <div
      className="home-section"
      id="home"
      style={{
        backgroundColor:
          "linear-gradient(27.51deg, #6C069C -30.15%, #0B67C9 39.35%, #187DF3 108.1%)",
      }}
    >
      <ImageBackground
        source={windowLogo}
        style={{ width, height: height + 100 }}
      >
        <Container style={{ flex: 1 }}>
          <Row style={{ paddingTop: 30, paddingLeft: 30 }}>
            <Col>
              <Image source={logo} style={{ width: 139, height: 32 }} />
            </Col>
          </Row>

          <Row
            style={{
              background: "white",
              marginLeft: 10,
              marginRight: 10,
              borderRadius: 5,
              marginTop: 50,
              paddingBottom: 15,
            }}
          >
            <View style={{ padding: 15 }}>
              <Text style={{ fontSize: 20, fontWeight: "600" }}>
                Pay ₹ {userDetails.amount}
              </Text>
            </View>
            <View
              style={{ height: getMobileOperatingSystem() === 'iOS' ? 0 : 10, backgroundColor: "rgba(35,0,70,0.1)" }}
            />
            <View>
              <Text style={{paddingVertical:5, color:'#1070da', fontWeight:'500'}}>Note - Only UPI payment is available</Text>
              <FlatList
                keyExtractor={(upiData) => upiData.id}
                data={getMobileOperatingSystem() === 'Android' ? upiData : []}
                renderItem={renderItem}
                contentContainerStyle={{ paddingBottom: 10 }}
                ItemSeparatorComponent={() => (
                  <View
                    style={{ height: 1, backgroundColor: "rgba(35,0,70,0.1)" }}
                  />
                )}
              />
            </View>
            <View style={{ padding: 10, backgroundColor: "rgba(35,0,70,0.1)" }}>
              <Text style={{ ...btnText, color: "black", fontWeight: "600" }}>
                Other UPI
              </Text>
            </View>
            {/* {inputValue && (
              <TouchableOpacity
                style={{ padding: 10 }}
                onPress={() => createPayment()}
              >
                <Text style={{ ...btnText, color: "black", fontWeight: "600" }}>
                  {inputValue}
                </Text>
              </TouchableOpacity>
            )} */}
            {/* <TouchableOpacity
              onPress={() => setShow(true)}
              style={{
                paddingVertical: 10,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Image
                source={"https://cdn-icons-png.flaticon.com/512/148/148764.png"}
                style={{ width: 18, height: 18, resizeMode: "contain" }}
              />
              <Text style={{ color: "green", paddingLeft: 5 }}>
                Enter UPI ID
              </Text>
            </TouchableOpacity> */}

            {
              <Row style={{ marginTop: 15, paddingLeft: 10, paddingRight: 10 }}>
                <Col>
                  <Input
                    placeholder={"Enter UPI ID"}
                    inputValue={inputValue}
                    onChangeText={(e) => setValue(e.target.value)}
                    searchImage={false}
                    style={{ borderWidth: 1 }}
                  />
                  {error && <Text style={{ ...btnText, paddingLeft: 0, paddingRight: 0, color: 'white', marginTop:10 }}>Please enter valid UPI ID</Text>}
                </Col>
                <Col style={{ paddingLeft: 0 }} className={"col-4"}>
                  <Button
                    variant="light"
                    onClick={(e) => {
                      if(inputValue.length>7 && inputValue.includes('@')){
                          setShow(false);
                          createPayment();
                        } else {
                          showError(true)
                        }
                    }}
                    style={btnStyle}
                  >
                    <Text
                      style={{ ...btnText, paddingLeft: 0, paddingRight: 0 }}
                    >
                      Proceed
                    </Text>
                  </Button>
                </Col>
              </Row>
            }
          </Row>
        </Container>
      </ImageBackground>
    </div>
  );
}

const styles = StyleSheet.create({
  textinput: {
    height: 26,
    borderWidth: 0.5,
    borderColor: "#0f0f0f",
    padding: 4,
    marginVertical: "1rem",
  },
  multiline: {
    borderWidth: 0.5,
    borderColor: "#0f0f0f",
    padding: 4,
    marginVertical: "1rem",
  },
});
export default Payments;

const upiData = [
  {
    id: "0",
    name: "Google Pay",
    logo: require('../assets/gpay.jpg'),
  },
  {
    id: "1",
    name: "PhonePe",
    logo: require('../assets/phonepe_thumb.png'),
  },
  {
    id: "2",
    name: "Amazon Pay",
    logo: require('../assets/Amazon-Pay-Logo.png'),
  },
  {
    id: "3",
    name: "Paytm",
    logo: require('../assets/Paytm_logo.png'),
  },
];
