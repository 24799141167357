import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import {
  Dimensions,
  ImageBackground,
  StyleSheet,
  Text,
  Image,
  FlatList,
  View,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { useHistory, Link, useParams } from "react-router-dom";
import Modal from "../components/Modal";
import windowLogo from "../assets/gradientbg.png";
import logo from "../assets/logo.png";

const { width, height } = Dimensions.get("window");

function Payu() {
    let history = useHistory();
  const paymentID = localStorage.getItem("paymentID");
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [paymentSuccess, setShowSuccess]  = useState(false);
  useEffect(() => {
    validatePayment();
  }, []);

  const validatePayment = (res) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic bW9iaWxldXNlcjpJJGVhbWUkNQ=");
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("payment", paymentID);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(
      "https://api.shoocal.com/pro/drab/userapp/verifyPaymentPayU",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
            hitOnSuccess(result.data)
            showSuccessPopUp(result.data.amount, result.data.store_id)
        } else {
            showFailedPopUp(result.data.mrn_no,result.data.store_id)
        }
      })
      .catch((error) => console.log("error", error));
  };

  
  const showSuccessPopUp = (paidAmt,store_id) => {
    setShowSuccess(true);
    setModalData({
      showIcon : true,
      title: "Payment Successful",
      description: `Thanks, We have received your payment.`,
      descriptionKeys : {
        'Paid_Amount' : 'Paid Amount - ₹' + paidAmt,
        'Transaction_ID' : 'Transaction ID - ' + paymentID
      },
      btn2Text: "OK",
      btn2Click: () => {
        history.push(`/pay/${store_id}`);
        setShowModal(false);
      },
    });
    setShowModal(true);
    
  };

  const showFailedPopUp = (mrn_no, store_id) => {
    let finalMRN =  mrn_no.replaceAll("/", "-");
    setShowSuccess(true)
    setModalData({
      title: "Transaction Failed",
      description:
        "Any amount deducted will be refunded within 3-5 working days into your bank account.",
      btn2Text: "Retry",
      btn2Click: () => {
        history.push(`/paybill/${finalMRN}/${store_id}`);
        setShowModal(false);
      },
    });
    setShowModal(true);
  };

  const hitOnSuccess = (data) => {
    
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic bW9iaWxlY2FzaGllcjpJJGVhbWUkNQ==");
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({"PaymentDetails":data.PaymentDetails});
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch("https://app.carehis.com//services/api/form/BILLDETAIL", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }

  return (
    <div
    className="home-section"
    id="home"
    style={{
      backgroundColor:
        "linear-gradient(27.51deg, #6C069C -30.15%, #0B67C9 39.35%, #187DF3 108.1%)",
    }}
  >
    <ImageBackground source={windowLogo} style={{ width, height: height }}>
      <Container style={{ width, height: height }}>
        <Row style={{ paddingTop: 30, paddingLeft: 30 }}>
          <Col>
            <Image source={logo} style={{ width: 139, height: 32 }} />
          </Col>
        </Row>

        <Row
          style={{
            // background: "white",
            marginLeft: 10,
            marginRight: 10,
            borderRadius: 5,
            marginTop: height*.05,
            padding: 15,
            paddingLeft: 10,
            paddingRight: 10,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {!paymentSuccess &&<><ActivityIndicator
            animating={true}
            style={{ marginBottom: 30 }}
            size={40}
            color={"white"}
          />
         
          <Text style={{ color: "white", fontSize: 20, textAlign:'center' }}>
            {
              
              'Checking Payment Status...'
            }
            
          </Text>
          </>}
         
          
        </Row>
        {!paymentSuccess &&<><Row
          style={{
            position: "absolute",
            bottom: 40,
            paddingLeft: 15,
            paddingRight: 15,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              marginTop: 15,
              color: "white",
              fontSize: 16,
            }}
          >
            NOTE: You will be redirected to another page once your payment is
            successful
          </Text>
          <Text
            style={{
              textAlign: "center",
              marginTop: 15,
              color: "white",
              fontSize: 16,
            }}
          >
            Do not close this page or press back.
          </Text>
        </Row></>}
        <Modal
          setShowModal={setShowModal}
          showModal={showModal}
          modalData={modalData}
        />
      </Container>
    </ImageBackground>
  </div>
  )
  
}

export default Payu;
